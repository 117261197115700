<!--eslint-disable vue/multi-word-component-names-->
<template>
  <div
    v-if="contentPage"
    class="page-root-container"
  >
    <div
      class="container-cms"
    >
      <h1>{{ contentPage.title }}</h1>
      <HTMLContent :content="contentPage.content" />
    </div>
    <PageGtm />
    <PageTrackingGtm
      v-if="contentPage"
      :page-title-gtm="contentPage.meta_title ? contentPage.meta_title : contentPage.title"
    />
  </div>
</template>
<script>
import { useContent } from '@vue-storefront/gemini';
import {
  defineComponent, useContext, useFetch, ref,
} from '@nuxtjs/composition-api';
import { useCache, CacheTagPrefix } from '@gemini/vsf-cache';
import PageGtm from '~/components/Gtm/PageGtm.vue';
import PageTrackingGtm from '~/components/Gtm/PageTrackingGtm.vue';
import { useGetAlternateMatching } from '~/helpers/alternate/getAlternateMatching';
import HTMLContent from '~/components/HTMLContent';
import { useI18n } from '../helpers/hooks/usei18n';
import { useKlaviyo } from '~/composables';

export default defineComponent({
  components: {
    HTMLContent,
    PageGtm,
    PageTrackingGtm,
  },
  props: {
    identifier: {
      type: [String],
      default: '',
    },
  },
  setup(props) {
    const { locale } = useI18n();
    const identifier = `${locale}/${props?.identifier}`;
    const { addTags } = useCache();
    const { error: nuxtError } = useContext();
    const refDataSeoAlternate = ref([]);
    const { getAlternatePageArray } = useGetAlternateMatching();
    const { klaviyoPagePush } = useKlaviyo();

    refDataSeoAlternate.value = getAlternatePageArray();

    const {
      page: contentPage,
      loadContent,
    } = useContent(`cmsPage-${identifier}`);

    useFetch(async () => {
      await loadContent({
        identifier: `${locale}/${props?.identifier}`,
      });
      if (!contentPage?.value?.content) {
        nuxtError({ statusCode: 404 });
      } else {
        addTags([{ prefix: CacheTagPrefix.View, value: 'page' }]);
        if (typeof window !== 'undefined') {
          await klaviyoPagePush(contentPage.value.title || contentPage.value.meta_title);
        }
      }
    });

    return {
      contentPage,
      refDataSeoAlternate,
    };
  },
  head() {
    const meta = [];
    const link = [];
    if (this.contentPage.meta_description) {
      meta.push(
        {
          hid: 'description',
          name: 'description',
          content: this?.contentPage?.meta_description.replace(/(<([^>]+)>)/gi, ''),
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this?.contentPage?.meta_description.replace(/(<([^>]+)>)/gi, ''),
        },
      );
    }
    if (this?.refDataSeoAlternate) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      link.push(...this.refDataSeoAlternate);
    }
    meta.push(
      { hid: 'robots', name: 'robots', content: 'index, follow' },
      { hid: 'og:title', name: 'og:title', content: this?.contentPage?.meta_title },
    );

    return {
      title: this?.contentPage?.meta_title,
      meta,
      link,
    };
  },
});
</script>

<style lang="scss" scoped>
.page-root-container {
  background-color: var(--_background-cms-primary);
  padding-top: 10px;
}
</style>

<style lang="scss">
.container-cms {
  margin: 50px auto 0;
  padding: 0 84px 135px 84px;
  max-width: 1366px;
  font-family: 'Spartan';
  font-size: 14px;
  line-height: 26px;

  @media screen and (max-width: 768px) {
    padding: 0 24px 50px 24px;
  }

  h4,
  h4 span,
  strong {
    font-size: var(--h4-font-size);
  }

  p {
    strong {
      font-size: 14px;
    }
  }

  .table-wrap {
    overflow-x: scroll;
    table {
      border-collapse: collapse;
      margin: 25px 0;
      font-size: 0.9em;
      font-family: sans-serif;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
      width: 100%;
      tr:nth-of-type(1) {
        background-color: #43464E;
        color: #ffffff;
        text-align: left;
      }
      th, td {
        padding: 12px 15px;
        p {
          min-width: 100px;
          margin: 0;
        }
        &:nth-of-type(3) p {
          min-width: 300px;
        }
      }
      tbody tr {
        border-bottom: 1px solid #dddddd;
      }
      tbody tr:nth-of-type(even) {
        background-color: #f3f3f3;
      }
      tbody tr.active-row {
        font-weight: bold;
        color: #009879;
      }
      td:nth-of-type(1) {
        font-weight: bold;
      }
    }
  }
}

.page-404 {
  text-align: center;

  .button-404 {
    width: 100%;

    p {
      display: inline-block;
      background: var(--c-secondary);
      padding: 20px 22px;
      font-size: 12px;
      letter-spacing: 1px;
      line-height: 13px;
      width: 250px;

      &:first-of-type {
        margin-right: 50px;
        @include for-mobile {
          margin: 0 auto;
        }
      }

      &:last-of-type {
        margin-left: 50px;
        @include for-mobile {
          margin: 0 auto;
          margin-top: 25px;
        }
      }

      a {
        color: #fff;
        font-weight: 700;
        font-family: 'Spartan';
      }
    }
  }
}
</style>
